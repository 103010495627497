<script setup lang="ts">

</script>

<template>
  <v-textarea
    v-bind="$attrs"
    variant="outlined"
  >
    <template #label>
      <slot name="label" />
    </template>
  </v-textarea>
</template>
